import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/Users/pgegenfurtner/Projekte/interness/atacama/atacama-packages/packages/web-core/src/templates/mdx/default.js";
import TeamMembers from '@interness/web-core/src/components/modules/TeamMembers';
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Wrapper = makeShortcode("Wrapper");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <Wrapper mdxType="Wrapper">
      <hr></hr>
      <h1>{`Über uns`}</h1>
  <span style={{
        textAlign: 'justify'
      }}>
        <p>{`  Das traditionsreiche Juweliergeschäft Dassmann wurde vor mehr als 50 Jahren von Uhrmachermeister Herbert Dassmann gegründet und schreibt seitdem am Standort Kupferstraße in Coesfeld bei Münster Erfolgsgeschichte.
Seit dem Jahr 2005 führen Erika und Gregor Dassmann das Juweliergeschäft in zweiter Generation und setzen die Tradition des Uhrmacherhandwerks und der Goldschmiedekunst ganz im Sinne des im Jahr 2010 verstorbenen Gründers auf höchstem Niveau fort.
Kompetente Beratung, vertrauensvolle Kundennähe, ausgezeichnete Serviceleistungen und ein sehr gutes Gespür für Trends zählen zu den Erfolgsfaktoren des Fachgeschäfts Juwelier Dassmann.`}</p>
  </span>
      <hr></hr>
  <TeamMembers mdxType="TeamMembers" />
    </Wrapper>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      